<template>
  <!-- Dialogo para indicar errores Angel -->
  <v-dialog
    v-model="dialogNuevaNomina.estatus"
    persistent
    max-width="1000"
  >
    <v-card>
    	<v-card-title primary-title>
    	  Solicitar requisición
    	</v-card-title>
    	<v-card-text>

    		<v-row>

    			<v-col cols="12" md="6" lg="4">
    				<v-autocomplete
		          filled
		          dense
		          clearable
		          v-model="tipo_requisicion"
		          :items="requisiciones"
		          label="Tipo de requisición"
		          persistent-hint
		          item-text="tipo_requisicion"
		          item-value="idtipo_requisiciones"
		        >
		        </v-autocomplete>

		    		<v-autocomplete
		          filled
		          dense
		          clearable
		          v-model="id_ciclo"
		          :items="ciclos"
		          label="Selecciona ciclo actual"
		          persistent-hint
		          item-text="ciclo"
		          item-value="id_ciclo"	          
		        >
		        </v-autocomplete>
            
            <v-autocomplete
              v-if="showSecondAutocomplete"
		          filled
		          dense
		          clearable
		          v-model="id_proyecto"
		          :items="proyectos"
		          label="Selecciona un proyecto"
		          persistent-hint
		          item-text="proyecto"
		          item-value="id_proyecto"	          
		        >
		        </v-autocomplete>

    			  <v-textarea
		        	v-model="comentarios"
		        	filled
		        	dense
		        	label="Comentarios"
		        	rows="3"
		        >	
		        </v-textarea>
          </v-col>

          <!-- Apartado para subir cotizaciones -->
          <v-col>
            <v-file-input
              v-model="files"
              accept="image/png, image/jpeg, image/bmp, application/pdf"
              placeholder="Selecciona tus imagenes"
              label="Subir Cotizaciones"
              multiple
              prepend-icon="mdi-paperclip"
              @change="cargarFotos()"
            >
              <template v-slot:selection="{index, text }">
                <v-chip
                  small
                  label
                  color="primary"
                   close
                   @click:close="eliminarfile(index, text)"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>  

            <!-- Botón para subir las cotizaciones -->
            <v-btn 
              v-bind:disabled="!files.length"  
              color="primary"
              tile
              small 
              @click="subirCotizacion() , verTabla = false"
            >
              <v-icon small left>mdi-plus</v-icon> 
              Subir Cotizaciones
            </v-btn>

             <v-row>
                <v-col cols="12" md="6" v-for="(img, i) in vistaPrevias" :key="i">
                  <v-card class="py-4 shadowCard" v-if="img.extensioArchivo != 'pdf'">
                    <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                    <v-btn 
                      color="error" 
                      small 
                      @click="eliminarFoto(img.url)"
                      top
                      right
                      absolute
                      fab
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                  <v-card class="py-4 shadowCard" v-if="img.extensioArchivo == 'pdf'">
                     <embed :src="img.url"  type="application/pdf">
                    <v-btn 
                      color="error" 
                      small 
                      @click="eliminarFoto(img.url)"
                      top
                      right
                      absolute
                      fab
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-card>              
                </v-col>
              </v-row>
          </v-col> 
    		</v-row>

    	

    		<v-btn 
          v-bind:disabled="!tipo_requisicion || !id_ciclo || !comentarios" 
          color="primary" 
          @click="dialog = true, verTabla = false"
          tile
          small
          class="mr-2"
        >
          <v-icon small left>mdi-plus</v-icon> 
          Partida
        </v-btn>

        <v-btn 
          v-bind:disabled="!tipo_requisicion || !id_ciclo || !comentarios" 
          color="primary" 
          @click="dialogPartidas.estatus = true"
          tile
          small
        >
          <v-icon small left>mdi-plus</v-icon> 
          Multiples Partidas
        </v-btn>


        <br>
        <br>
         
  			<!-- <v-btn color="primary" @click="dialogSubirComprobante = true"><v-icon>mdi-plus</v-icon> Subir Cotización</v-btn> -->

        <v-data-table
        	v-if="verTabla"
			    :headers="headers"
			    :items="detalles"
			    dense
			    class="elevation-0 mt-4"
			  >

			    <template v-slot:item.id_plantel="{ item }">
			      <span>{{ planteles.find( el=> el.id_plantel == item.id_plantel ).plantel }}</span>
			    </template>

			    <template v-slot:item.id_elemento_requisicion="{ item }">
			      <span>{{ elementos.find( el=> el.id_elemento_mantenimiento == item.id_elemento_requisicion ).elemento_mantenimiento }}</span>
			    </template>

			  </v-data-table>

    	</v-card-text>

    	<v-card-actions>
		    <v-btn dark block @click="dialogNuevaNomina.estatus = false" color="primary">Guardar</v-btn>
    	</v-card-actions>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
        	<v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text">
        	<v-row>
        		<v-col cols="12" md="6">
			    		<v-autocomplete
			    		  label="Elemento de requisición"
			          filled
			          dense
			          clearable
			          v-model="editedItem.id_elemento_requisicion"
			          :items="filterElementos"
			          persistent-hint
			          item-text="elemento_mantenimiento"
			          item-value="id_elemento_mantenimiento"
			        >
			        </v-autocomplete>

              <!-- Plantel estado de resultados -->
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.id_plantel_resultados"
                :items="planteles"
                label="Plantel stdo. resultados"
                persistent-hint
                item-text="plantel"
                item-value="id_plantel"
              >
              </v-autocomplete>

			    		<v-autocomplete
			          filled
			          dense
			          clearable
			          v-model="editedItem.id_plantel"
			          :items="planteles"
			          label="Plantel Egresos"
			          persistent-hint
			          item-text="plantel"
			          item-value="id_plantel"
			        >
			        </v-autocomplete>

			    		<v-text-field
			          filled
			    		  label="Concepto"
			          dense
			          v-model="editedItem.concepto"
			    		></v-text-field>

			    		<v-text-field
			          filled
			    		  label="Cantidad"
			          dense
			          type="number"
			          v-model="editedItem.cantidad"
			    		></v-text-field>

              <v-checkbox label="¿Con IVA?" v-model="conIva" ></v-checkbox>

              <v-text-field
                v-if="conIva"
                filled
                label="Costo Sin IVA"
                dense
                type="number"
                v-model="costo_sin_iva"
              ></v-text-field>

			    		<v-text-field
			          filled
			    		  label="Costo Unit."
			          dense
			          type="number"
			          v-model="editedItem.costo_unitario"
			    		></v-text-field>

			    		<v-text-field
			          filled
			    		  label="Costo total"
			          dense
			          readonly
			          v-model="editedItem.costo_total"
			    		></v-text-field>

              <v-checkbox label="¿Devolución?" v-model="devolucion"  @change="onCursoChange" ></v-checkbox>

              <v-autocomplete
			          filled
			          dense
			          clearable
			          v-model="editedItem.id_alumno"
			          :items="alumnos"
			          label="Selecciona el alumno"
			          persistent-hint
			          item-text="nombre_completo"
			          item-value="id_alumno"
                v-if="devolucion"
			        >
			        </v-autocomplete>

              <v-autocomplete
			          filled
			          dense
			          clearable
			          v-model="editedItem.id_grupo"
			          :items="grupos"
			          label="Selecciona el grupo"
			          persistent-hint
			          item-text="grupo"
			          item-value="id_grupo"
                v-if="devolucion"
			        >
			        </v-autocomplete>

	    			</v-col>

            <v-col cols="12" md="6" class="pa-0">
              <v-card-text align="center" class="pa-0">
                <div v-if="!file">
                  <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
                    <div class="dropZone-info" @drag="onChange">
                      <span class="fa fa-cloud-upload dropZone-title"></span>
                      <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
                      <div class="dropZone-upload-limit-info">
                        <div>Extensión: png, jpg, jpeg, svg</div>
                        <div>Tamaño máximo: 10 MB</div>
                      </div>
                    </div>
                    <input type="file" @change="onChange">
                  </div>
                </div>

                <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
                <v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>
                
              </v-card-text>

            </v-col>
            
        	</v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn dark block @click="agregar()" color="primary">Agregar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


    <MultiplesPartidas 
      v-if="dialogPartidas.estatus"
      :dialogPartidas="dialogPartidas"
      :tipo_requisicion="tipo_requisicion"
      @cargarPartidaMultiple="cargarPartidaMultiple"
    />

  </v-dialog>
</template>


<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'


	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores';
  import MultiplesPartidas     from '@/components/egresos/MultiplesPartidas.vue';

  import leerEgresos           from '@/mixins/leerEgresos'


	export default {
		props:[
			'dialogNuevaNomina'
	  ],

  	components:{
      Alerta,
      carga,
      MultiplesPartidas
    },

    mixins: [ validarErrores, leerEgresos ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Elemento Compra' , value: 'id_elemento_requisicion'  },
        { text: 'Concepto'        , value: 'concepto'                 },
        { text: 'Plantel'         , value: 'id_plantel'               },
        { text: 'Cantidad'        , value: 'cantidad'                 },
        { text: 'Costo Unit.'     , value: 'costo_unitario'           },
        { text: 'Total'           , value: 'costo_total'              },
        { text: 'Actions'         , value: 'actions', sortable: false },
      ],

      ciclo: null,
      comentarios:'Pago servicio de las sucursales',

      opVisualizacion: 1,

      editedIndex: -1,

      editedItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                'Pago de', 
		    cantidad:                1, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
        id_alumno:               null, 
        id_grupo:                null, 
    		detalles:[],
        id_plantel_resultados:   0,
      },

      defaultItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                'Pago de', 
		    cantidad:                1, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
        id_alumno:               null, 
        id_grupo:                null, 
    		detalles:[],
        id_plantel_resultados:   0,
      },

      detalles:[],
      empleados:[],
      elementos:[],
      planteles:[],
      verTabla: true,
      fotos:[],
      files: [],
      vistaPrevias:[],
      dialogEliminar: false,

      id_usuario_solicita:'',
      id_sucursal_solicita:'',
      id_requisicion_compra_estatus:'',
      id_usuario_ultimo_cambio:'',
	  

      id_usuario_compra: 28,
      id_ciclo:0,
      id_proyecto:0,
      comentarios:'',
      idrequisicion_compra : null,
      ciclos:[],
      proyectos:[],
      opEscuela:1,
      tipo_requisicion:0,
      requisiciones:[],
      dialogPartidas:{
        estatus: false,
        devolucion: false,
        alumnos:[],
        grupos:[],
      },
      devolucion: false,

      conIva:false,
      costo_sin_iva:0,

      label:'',
      archivos:null,
      vistaPrevia: null,
      textoImagen:'',
      monto:0,
      file: '',
      dragging: false,
      pago_anterior:0,
      banco:'',

      texto_completo:'',

      isReciboAgua: null,
      isTelmex:     null,
      isIonos:      null,
      isLuz:        null,
      isMeta:       null,
      isDidi:       null,
      isRenta:      null,
      isSaldo:      null,
      categoriaRecibo: 0,

    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
     
      showSecondAutocomplete() {
        return true // this.id_ciclo === 136 || this.id_ciclo === 137 || this.id_ciclo === 202 || this.id_ciclo === 253; // muestra el segundo autocomplete solo cuando id_ciclo es igual a "01"
      },

      filterElementos( ){
        return this.elementos
        // return this.elementos.filter( el => el.id_tipo_elemento == this.tipo_requisicion )
      }
      
    },

    watch: {

      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      costo_sin_iva( ){
        this.editedItem.costo_unitario = this.costo_sin_iva * 1.16
      },

      "editedItem.cantidad"( value ){
      	if( value && this.editedItem.costo_unitario ){ this.editedItem.costo_total = value * this.editedItem.costo_unitario }
      },

      "editedItem.costo_unitario"( value ){
      	if( this.editedItem.cantidad && value ){ this.editedItem.costo_total = value * this.editedItem.cantidad }
      },

    	"editedItem.id_elemento_requisicion"( value ){
    		if( this.editedItem.id_elemento_requisicion ){
      		this.editedItem.concepto = 'Pago de: ' + this.elementos.find( el => el.id_elemento_mantenimiento == this.editedItem.id_elemento_requisicion).elemento_mantenimiento
    		}
      },

      "editedItem.id_elemento_requisicion"(value){

        if( value ){
          let existeElemento = this.elementos.find( el => el.id_elemento_mantenimiento == value)
          console.log( value)
          this.editedItem.id_plantel_resultados = existeElemento ? existeElemento.id_plantel : 0
        }else{
          this.editedItem.id_plantel_resultados = 0
        }
      },

      archivos( val ){
        this.total_pagado       = 0
        this.isPracticaja       = false
        this.isTransferencia    = false
        this.isSeven            = false
        this.isOXXO             = false
        this.isWeb              = false
        this.isTarjeta          = false
        this.isDirectoBancoBBVA = false
        this.isDirectoBANREGIO  = false
        if( !val ){ this.vistaPrevia = null } 
      },
    },

    async created () {
	  this.cargar = true
	  this.id_usuario_solicita             = this.getdatosUsuario.iderp
      this.id_sucursal_solicita          = this.getdatosUsuario.id_plantel
      this.id_usuario_ultimo_cambio      = this.getdatosUsuario.iderp
      
      await this.getCiclosActivos()
      await this.getProyectos()
      await this.getPlanteles()
      await this.getElementosCompras()
      await this.getTipoRequisiciones()
      await this.getAlumnos()
      await this.getGrupos()

    	this.cargar = false
    },

    methods: {
  		//Angel
  		cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase642(element, formData)
        })
      },

	    getBase642(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

          const nombreSplit = file.name.split('.')
          const extensioArchivo = nombreSplit[ nombreSplit.length - 1 ]
          console.log(file)
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file, extensioArchivo
          })
        };
      },

	    eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },

      eliminarfile(index, value){
        this.files = this.files.filter(el=>{return el.name != value})
      },

      getCiclosActivos () {
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getProyectos () {
        this.proyectos = []
        return this.$http.get('consultar.proyectos').then(response=>{
        	this.proyectos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	getPlanteles () {
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
          this.planteles.push({
            plantel: 'TODOS',
            id_plantel: 101
          })
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getElementosCompras () {
        this.elementos = []
        return this.$http.get('consultar.elemtos.compra').then(response=>{
        	this.elementos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTipoRequisiciones() {
        this.requisiciones = []
        return this.$http.get('consultar.requisiciones').then(response=>{
        	this.requisiciones = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close(){
      	this.dialog = false
      	this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.verTabla = true
      },

	    guardar(){},

      async agregar(){
      	// Guardamos el detalle
                                                                                     
	      let elemento = this.elementos.find( el=> el.id_elemento_mantenimiento == this.editedItem.id_elemento_requisicion )
        let plantel  = this.planteles.find( el=> el.id_plantel == this.editedItem.id_plantel )
	
	      // Validamos que el elemento se haya seleccionado correctamente
      	if( !elemento ){ return this.validarErrorDirecto('Favor de seleccionar un elemento') }
        // Validamos que el palntel se haya seleccionado correctamente
        if( !plantel ){ return this.validarErrorDirecto('Favor de seleccionar un plantel') }
        // Validamos que la cantidad se haya seleccionado correctamente
        if( this.editedItem.cantidad == ''){ return this.validarErrorDirecto('Favor agregar la cantidad') }
        // Validamos que el costo se haya seleccionado correctamente
        if( this.editedItem.costo_unitario == ''){ return this.validarErrorDirecto('Favor de agregar el costo unitario') }

      	// Desestructuramos el elemento
      	const { elemento_mantenimiento, automatico, id_elemento_requisicion } = elemento

      	let detalles = []
      	detalles.push( this.editedItem )
		
		    this.cargar = true
        let fotos = []
        if(this.vistaPrevias.length){
          let respuesta = await this.subirFotosServidor( )
          fotos = respuesta.data
        }

	      var payload = {
      		registro:                      this.idrequisicion_compra,
      		id_usuario_solicita:           this.id_usuario_solicita,
		      id_sucursal_solicita:          this.id_sucursal_solicita,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.id_usuario_ultimo_cambio,
		      id_usuario_compra:             28,
		      tipo_requisicion:              this.tipo_requisicion,
		      id_ciclo:                      this.id_ciclo,
          id_proyecto:                   this.id_proyecto,
		      comentarios:                   this.comentarios,
		      detalles,
			    fotos:[]
	      }

        if (this.detalles.some(detalle => JSON.stringify(detalle) === JSON.stringify(this.editedItem))) {
          this.validarError('Esta partida ya ha sido agregada anteriormente en esta requisición')
          return
        }

        return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( response.data.message )
        	this.detalles.push( this.editedItem )
        	this.idrequisicion_compra = response.data.idrequisicion_compra
        	this.cargar      = false
	      	// Agregamos primero los datos
	      	this.dialog = false
	      	this.$nextTick(() => {
	          this.editedItem = Object.assign({}, this.defaultItem)
	          this.editedIndex = -1
	        })
	        this.verTabla = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
        

      },


      async subirCotizacion(){
      	// Guardamos el detalle

        if( !this.idrequisicion_compra){return this.validarErrorDirecto('Favor de crear primero la requisición')}

		      this.cargar = true
          let fotos = []
          if(this.vistaPrevias.length){
            let respuesta = await this.subirFotosServidor( )
            fotos = respuesta.data
          }

  	      var payload = {
        		registro:                      this.idrequisicion_compra,
        		id_usuario_solicita:           this.id_usuario_solicita,
  		      id_sucursal_solicita:          this.id_sucursal_solicita,
  		      id_usuario_ultimo_cambio:      this.id_usuario_ultimo_cambio,
  		      id_usuario_compra:             28,
  		      tipo_requisicion:              this.tipo_requisicion,
  		      id_ciclo:                      this.id_ciclo,
  		      comentarios:                   this.comentarios,
  		      detalles:[],
  			    fotos
  	      }

          return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
            this.validarSuccess( response.data.message )
          	this.cargar      = false
  	      	// Agregamos primero los datos
  	      	this.dialog = false
  	      	this.$nextTick(() => {
  	          this.editedItem = Object.assign({}, this.defaultItem)
  	          this.editedIndex = -1
  	        })
  	        this.verTabla = true
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        

        },

	    subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`egresos.subir.fotos`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarPartidaMultiple( value ){
        for( const i in value){
          this.detalles.push( value[i] )
        }

        this.dialogPartidas.estatus = false

        var payload = {
          registro:                      this.idrequisicion_compra,
          id_usuario_solicita:           this.id_usuario_solicita,
          id_sucursal_solicita:          this.id_sucursal_solicita,
          id_requisicion_compra_estatus: 1,
          id_usuario_ultimo_cambio:      this.id_usuario_ultimo_cambio,
          id_usuario_compra:             28,
          tipo_requisicion:              this.tipo_requisicion,
          id_ciclo:                      this.id_ciclo,
          comentarios:                   this.comentarios,
          detalles:                      value,
          fotos:[]
        }

        return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( response.data.message )
          this.idrequisicion_compra = response.data.idrequisicion_compra
          this.cargar               = false
          // Agregamos primero los datos
          this.verTabla = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

     onCursoChange() {
      this.cargar = true;
      this.cargar = false;
     },

     getAlumnos () {
      	this.cargar = true
        this.alumnos = []
        return this.$http.get('get.all.alumnos.devolucion').then(response=>{
        	this.alumnos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGrupos () {
      	this.cargar = true
        this.grupos = []
        return this.$http.get('get.all.grupos.devolucion').then(response=>{
        	this.grupos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


    },
  }
</script>

<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>